import React, {useState} from "react";
import { View, Text, StyleSheet } from "react-native";
import Captcha from "../../Services/recaptcha";

const Login = () => {

    return(
        <View>
            <Text>
                Login
            </Text>
        </View>
    )
}
 const styles = StyleSheet.create({
    container: {
        flex: 1,
    }
 })

 export default Login