import React, {useState} from "react";
import { View, Text, StyleSheet } from "react-native";

const SignIn = () => {

    return(
        <View>
            <Text>
                SignIn
            
            </Text>
        </View>
    )
}
 const styles = StyleSheet.create({
    container: {
        flex: 1,
    }
 })

 export default SignIn