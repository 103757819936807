import React, {useContext, useState, useEffect} from 'react';
import { Image, StyleSheet, Text, View, Dimensions, Platform, TouchableOpacity, ScrollView } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { customStyle } from '../utils/CustomStyles';
import { useNavigation } from '@react-navigation/native';
import UserContext from '../Contexts/UserContext';
import TokenContext from '../Contexts/TokenContext';

import Captcha from '../Services/recaptcha'

import logo from '../../assets/logo.png'
import topRounded from '../../assets/topRounded.png'
import appstoregoogleplay from '../../assets/app-store-google-play.png'
import howItWorks from '../../assets/hoItWorks.png'
import myBusiness from '../../assets/myBusiness.png'
import addButton from '../../assets/Add.png'
import arrowButton from '../../assets/Arrow.png'
import warning from '../../assets/warning.png'


const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const Homepage = () => {
    const navigation = useNavigation();
    const [user, setUser] = useContext(UserContext)
    const [privacyPolicy, setPrivacyPollicy] = useState(true)
    const [token, setToken] = useContext(TokenContext)
    const [openQrcode, setOpenQrcode] = useState(false)

    const privacyPolicyBox = async () => {
      try {
        await AsyncStorage.setItem('@QRPERK: PrivacyPolicy', true);
      } catch (e) {
      }
      setPrivacyPollicy(false)
    };
  
    return (
      <ScrollView style={{flexGrow: 1}}>
      <View style={customStyle.container}>
        {Platform.OS === 'web' ?
            privacyPolicy &&
            <>
                <View style={{position: 'absolute', zIndex: 3, flex: 1, width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', marginTop: -100 }}>
                <View style={{width: '85%', height: 160, padding: 20, alignItems: 'center', justifyContent: 'space-between', backgroundColor: '#343434', borderRadius: 16, flexDirection: 'row'}}>
                    <View>
                    <Image source={warning} style={{width: 40, height: 40,}} />
                    </View>
                    <View>
                    <Text style={[customStyle.fontYellow, {fontSize: 12}]}>By using this site you agree to our terms</Text>
                    <Text style={[customStyle.fontYellow, {fontSize: 12}]}>and conditions and our privacy policy.</Text>
                    </View>
                </View>
    
                <TouchableOpacity onPress={()=>{privacyPolicyBox()}} style={[customStyle.simpleBoto, {marginTop: -45, marginRight: -190 }]}>
                    <Text style={customStyle.fontYellow}>okay</Text>
                </TouchableOpacity>            
                </View>
                <View style={{backgroundColor: '#000000', opacity: 0.85, position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 2, flex: 1}}></View>
            </>
        : null}
        


        <View style={styles.boxTop}>
          <Image source={logo} style={{width: 224, height: 58}} />
        </View>

        <TouchableOpacity onPress={()=>{setOpenQrcode(true)}} style={{alignItems: 'center', backgroundColor: '#ffffff', height: 300}}>
          <Image source={topRounded} style={{width: 430, height: 15}} />
          <Captcha />    
        </TouchableOpacity>

        <View style={{marginTop: -80, alignItems: 'center', flex: 1, marginBottom: -10, zIndex: 2}}>
            <Image source={user != '' ? arrowButton : addButton} style={{width: 60, height: 60,}} />
        </View>
        
        
        <TouchableOpacity onPress={()=>{setOpenQrcode(true)}} style={{alignItems: 'center', justifyContent: 'center', padding: 10, backgroundColor: '#000000'}}>
            <Text style={{fontFamily: 'Lexend-Regular', color: '#d8257d', fontSize: 22}}>
                {user != '' ? 'just use your code' : 'create my first code'}
            </Text>
        </TouchableOpacity>
        

        <View style={{backgroundColor: '#2d2d2d', padding: 25, alignItems: 'baseline'}}>
          <Text style={customStyle.fontGrey}>
            Hello, <Text style={customStyle.fontGreen}>{user.name == null ? 'Anonymous' : user.name}</Text>! Welcome to QrPERK!
          </Text>
          <Text style={customStyle.fontGrey}>
            Dont loose you Perks. Just 
                <TouchableOpacity onPress={()=>{navigation.navigate('Login')}}>
                    <Text style={customStyle.fontGreen}>
                        Sign In
                    </Text>
                </TouchableOpacity> 
                <Text>or</Text>
                <TouchableOpacity onPress={()=>{navigation.navigate('SignIn')}}>
                    <Text style={customStyle.fontGreen}>
                        Sign Up
                    </Text>
                </TouchableOpacity>
          </Text>
        </View>
  
        <View style={{padding: 25, flexDirection: 'row', alignItems: 'center'}}>
          <View>
            <Image source={howItWorks} style={{width: 80, height: 74}} />
          </View>
          <View style={{paddingLeft: 15, width: 270}}>
            <Text style={customStyle.fontGrey}>To give som coffees and more incredible stuffs, just create your QrPerk Code and have some fun!</Text>
          </View>
        </View>
  
        <View style={customStyle.divider}></View>
  
        <View style={{padding: 25, flexDirection: 'row', alignItems: 'center'}}>
          <View>
            <Image source={myBusiness} style={{width: 80, height: 74}} />
          </View>
          <View style={{paddingLeft: 15, width: 270}}>
            <Text style={customStyle.fontGrey}>Grow up your nusiness!</Text>
            <TouchableOpacity style={customStyle.simpleBoto}>
              <Text style={customStyle.fontGreen}>i want it!</Text>
            </TouchableOpacity>
          </View>
        </View>
        
        <View style={{backgroundColor: '#2d2d2d', padding: 20, alignItems: 'center', justifyContent: 'center', flex: 1}}>
          {Platform.OS === 'web' ? <Image source={appstoregoogleplay} style={{width: 300, height: 41}} /> : null }
        </View>
        <TouchableOpacity onPress={()=>{setToken('')}}>
          <Text>{token}</Text>
        </TouchableOpacity>
      </View>
      </ScrollView>
    );
  }
  
  const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: '#fff',
      alignItems: 'center',
      justifyContent: 'center',
    },
    boxTop: {
      height: Platform.OS === 'ios' ? 80 : 140,
      backgroundColor: '#000000',
      alignItems: 'center',
      justifyContent: 'center'
    },
    boxContent: {
      alignItems: 'center', 
      justifyContent: 'center', 
      padding: 10,
    },
  
  });
  
  export default Homepage