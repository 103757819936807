import React, { useRef, useCallback, useState, useEffect, useContext } from 'react';
import {
  SafeAreaView,
  StyleSheet,
  View,
  Text,
  StatusBar,
  Button,
  TouchableOpacity,
  Image
} from 'react-native';
import QRCode from 'react-native-qrcode-svg';
import AsyncStorage from '@react-native-async-storage/async-storage';
import UserContext from '../Contexts/UserContext';
import api from './api';
import qr from '../../assets/Qr.png'
import addButton from '../../assets/Add.png'
import arrowButton from '../../assets/Arrow.png'

import Recaptcha from 'react-native-recaptcha-that-works';

const Captcha = () => {

    const size = 'invisible'; // 'invisible' | 'compact' | 'normal'
    const [key, setKey] = useState('<none>');
    const [user, setUser] =  useContext(UserContext)
    const [userLocal, setUserLocal] = useState()
    const [error, setError] = useState()
    const [captchaToken, setCaptchaToken] =  useState()
    const $recaptcha = useRef();
    const [openRecaptcha, setopenRecaptcha] = useState(false)

    if (userLocal > 0 ){
      const obj = JSON.parse(userLocal)
      setUser(obj)
    }
    const handleOpenPress = useCallback(() => {
      $recaptcha.current.open();
    }, []);

    useEffect(()=>{
      if (captchaToken != null) {
        api.post('/users',{"token": captchaToken})
          .then((response) => setUser(response.data))
          .catch((err) => {
            console.error("ops! ocorreu um erro" + err);
          });
        }      
    }, [captchaToken])


        return(
            
          <>
            <View style={styles.container}>

                {user != '' ?
                  <View style={{marginTop: -45}}>
                    <QRCode value={user.qrcode+'|Anonymous'} size={175}/>
                  </View>
                : 
                <TouchableOpacity onPress={handleOpenPress} style={{marginTop: -40}}>
                    <Image source={qr} style={{width: 175, height: 175}} />
                </TouchableOpacity>
                }

            </View>
    
            <Recaptcha
              ref={$recaptcha}
              lang="en"
              headerComponent={<></>}
              footerComponent={<></>}
              siteKey="6Lcxm5sdAAAAAP9zk-debaK-oDmKPXhu_FAPO4po"
              baseUrl="https://qrperk.com"
              size={size}
              theme="light"
              onLoad={()=>{}}
              onClose={()=>{}}
              onError={(err) => {
                console.warn('error', err);
                alert('An error occurred. Try again, please.');
              }}
              onExpire={() => alert('Seu token expirou')}
              onVerify={(captchaToken) => {
                setCaptchaToken(captchaToken)
              }}
            />

          </>
          
    )

}

const styles = StyleSheet.create({
    safeArea: {
      flex: 1,
    },
    container: {
      backgroundColor: '#FFFFFF',
      justifyContent: 'center',
      alignItems: 'center',
      flex: 1,
    },
  });
export default Captcha