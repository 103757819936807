import { createNativeStackNavigator } from "@react-navigation/native-stack";
import SignIn from "../Pages/SignIn";
import Login from "../Pages/Login";
import Homepage from "../Pages/homepage";

const Stack = createNativeStackNavigator()

export default function Routes(){

    return(
        <Stack.Navigator>
            <Stack.Screen name="Homepage" component={Homepage} options={{headerShown: false}}  />
            <Stack.Screen name="Login" component={Login} />
            <Stack.Screen name="SignIn" component={SignIn} />
        </Stack.Navigator>
    )
}