import { useState, useContext } from 'react';
import { StatusBar } from 'expo-status-bar';
import { useFonts } from 'expo-font';
import Homepage from './src/Pages/homepage';
import UserContext from './src/Contexts/UserContext';
import TokenContext from './src/Contexts/TokenContext';
import api from './src/Services/api';
import { NavigationContainer } from '@react-navigation/native';
import Routes from './src/routes';
import GetInitialData from './src/Data/getInitialData';
if(__DEV__) {
  import("./ReactotronConfig")
}

export default function App() {
  const [user, setUser] = useState([])

  const [fontsLoaded] = useFonts({
    'Lexend-Regular': require('./assets/fonts/Lexend-Regular.ttf'),
    'Iconsax': require('./assets/fonts/iconsax.ttf'),
  });

  return(
    <NavigationContainer>
      <UserContext.Provider value={[user, setUser]}>
        <GetInitialData />
        <Routes />
      </UserContext.Provider>
    </NavigationContainer>  
  )
}
 