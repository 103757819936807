import React from "react";
import { StyleSheet, Dimensions, Platform } from "react-native";
import { useFonts } from "expo-font";


const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export const customStyle = StyleSheet.create({

    mainContainer: {
        flex: 1,
        backgroundColor: '#000000',
        color: '#696969',
        paddingTop: Platform.OS === 'ios' ? '' : 60,
    },
    divider: {
        marginHorizontal: 25,
        height: 1,
        backgroundColor: '#757575',
    },
    container: {
        flex: 1,
        backgroundColor: '#212121',
        color: '#696969',
    },
    fontWhite: {
        fontFamily: 'Lexend-Regular',
        color: '#ffffff',
        lineHeight: 25,
    },
    fontGrey: {
        fontFamily: 'Lexend-Regular',
        color: '#838383',
        lineHeight: 25,
    },
    fontPink: {
        fontFamily: 'Lexend-Regular',
        color: '#d8257d',
        lineHeight: 25,
    },
    fontGreen: {
        fontFamily: 'Lexend-Regular',
        color: '#a7ff42',
        lineHeight: 25,
    },
    fontYellow: {
        fontFamily: 'Lexend-Regular',
        color: '#ffb900',
        lineHeight: 25,
    },
    simpleBoto: {
        backgroundColor: '#3f3f3f',
        marginVertical: 4,
        paddingHorizontal: 10,
        borderRadius: 15,
        width: 100,
        justifyContent: 'center',
        alignItems: 'center',
    },
    icone: {
        fontFamily: 'iconsax',
    }
})