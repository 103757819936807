import React, {useState, useEffect, useContext} from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import UserContext from "../Contexts/UserContext";
import api from "../Services/api";
import { View, Text } from "react-native";

const GetInitialData = () => {
    const [user, setUser] = useContext(UserContext)
    const [userLocal, setUserLocal] = useState([])
    const [confereUserLocal, setConfereUserLocal] =  useState('')


    useEffect(()=>{
          api.get('/me','')
            .then((response) => setUserLocal(response.data))
            .catch((err) => {
              console.error("ops! ocorreu um erro" + err);
            });    
      }, [])

    async function SaveUser() {
        const userJson = JSON.stringify(user)
        await AsyncStorage.setItem('@QRPERK: user', userJson)
    }

    useEffect(()=>{
        SaveUser()
    }, [user])

    return(
        <>
            <View style={{marginTop: 40}}>
                <Text>Status: {user.id}</Text>
            </View>
        </>
    )
}
export default GetInitialData